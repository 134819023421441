import React, { useCallback, useEffect, useState } from 'react';
import { List, notification } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import dayjs from 'dayjs';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { Note } from 'types/visitTypes';
import { hasPermission } from 'utils/utils';
import { getRequest, postRequest } from 'api/apiClient';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';


interface NotesTabProps {
    isActive: boolean;
}

const NotesTab: React.FC<NotesTabProps> = ({ isActive }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);

    const hasVisitorlogWrite = hasPermission(tokenScopeList, orgId, 'o', 'visitorlog:w');
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [visitNotes, setVisitNotes] = useState<Note[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = OpForm.useForm();

    // Fetch visit notes
    const fetchVisitNotes = useCallback(async () => {
        setLoading(true);
        try {
            const visitNotes = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorLog`);
            setVisitNotes(visitNotes.data);
        } catch (error) {
            console.log("Failed to fetch visit notes.");
        } finally {
            setLoading(false);
        }
    }, [orgId, selectedVisit?.id]);

    useEffect(() => {
        if (isActive) {
            fetchVisitNotes();
        }
    }, [isActive, fetchVisitNotes]);

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        const addVisitNote = async () => {
            setLoading(true);
            try {
                await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorLog`, {
                    logUserId: globalUserId,
                    log: values.note
                });
                const visitNotes = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorLog`);
                setVisitNotes(visitNotes.data);
                form.resetFields();
                notification.success({
                    message: "Success",
                    description: "Note saved successfully.",
                    placement: 'bottomRight',
                });
            } catch (error) {
                notification.error({
                    message: "ERROR",
                    description: "Failed to save note.",
                    placement: 'bottomRight',
                });
            } finally {
                setLoading(false);
            }
        };
        addVisitNote();
    }, [globalUserId, orgId, form, selectedVisit?.id]);

    return (
        <div>
            {(hasVisitorlogWrite && (hasAllvisitorsWrite || globalUserId === selectedVisit?.host.userId)) && (
                <OpForm
                    form={form}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                >
                    <OpForm.TextAreaInput name="note" rules={[{ required: true, message: 'Please enter a note.' }]}
                        rows={4} placeholder="Add new note" />
                    <OpForm.Button type="primary" htmlType="submit" >
                        Save New Note
                    </OpForm.Button>
                </OpForm>
            )}
            <List
                dataSource={visitNotes}
                loading={loading}
                renderItem={(note: Note) => (
                    <List.Item
                        key={note.id}
                        style={{
                            border: '1px solid #d9d9d9',
                            borderRadius: '12px',
                            padding: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        <List.Item.Meta
                            description={dayjs(note.timestamp).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)} />
                        {note.log}
                    </List.Item>
                )}
            />
        </div>
    );
};

export default NotesTab;
