import React, { useState, useEffect } from 'react';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { OpDrawer } from 'components/customAntd/DLS/OpDrawer/OpDrawer';
import { useSelector } from 'react-redux';
import VisitorsTab from './tabs/VisitorsTab';
import DetailsTab from './tabs/DetailsTab';
import { RootState } from 'store/store';
import { DRAWER_WIDTH } from 'constants/ui';
import { hasPermission } from 'utils/utils';
import VisitorsDrawerFooter from './footer/VisitorsDrawerFooter';
import { RedFlag } from 'types/redFlagTypes';
import VisitorsDrawerHeader from './header/VisitorsDrawerHeader';
import NotesTab from './tabs/NotesTab';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import GuestPassTab from './tabs/GuestPassTab';
import { getRequest } from 'api/apiClient';
import dayjs from 'dayjs';
import axios from 'axios';
import GuestPassLogTab from './tabs/\bGuestPassLogTab';

interface VisitorsDrawerProps {
	open: boolean;
	redFlags: RedFlag[];
	isProfileDrawerOpen: boolean;
	onClose: () => void;
	setIsProfileDrawerOpen: (open: boolean) => void;
	tab?: string;
}

const VisitorsDrawer: React.FC<VisitorsDrawerProps> = ({ open, redFlags, isProfileDrawerOpen, onClose, setIsProfileDrawerOpen, tab }) => {
	const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
	const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
	const { selectedVisit } = useSelector((state: RootState) => state.visits);

	const [isIntegrated, setIsIntegrated] = useState<boolean>(false);
	const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
	const [activeKey, setActiveKey] = useState<string>('visitors');
	const [loading, setLoading] = useState<boolean>(false);
	const hasVisitorlogRead = hasPermission(tokenScopeList, orgId, 'o', 'visitorlog:r');
	const hasGuestpassRead = hasPermission(tokenScopeList, orgId, 'o', 'guestpass:r');

	// Reset the active tab to 'visitors' when the drawer opens
	useEffect(() => {
		if (open) {
			setActiveKey(tab || 'visitors');
		}

		const checkIntegration = async () => {
			setLoading(true);
			try {
				const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);
				const initialData = integrationData.data[0];

				const { msiAltaOrgId, msiAltaToken, msiAltaTokenExpUtc, guestPassEnabled } = initialData;

				const hasOrgId = !!msiAltaOrgId;
				const hasToken = !!msiAltaToken;
				const tokenIsValid = !msiAltaTokenExpUtc || dayjs(msiAltaTokenExpUtc).isAfter(dayjs());
				const guestPassEnabledCondition = guestPassEnabled === 1;

				// Set isIntegrated to true if all conditions are met
				if (hasOrgId && hasToken && tokenIsValid && guestPassEnabledCondition) {
					setIsIntegrated(true);

					const response = await axios.post(`https://api.openpath.com/auth/accessTokens/${msiAltaToken}/validate`);
					setIsTokenValid(response.data.data.isValid);
				} else {
					setIsIntegrated(false);
				}
			} catch (error) {
				console.log("Failed to check integration.");
			} finally {
				setLoading(false);
			}
		}
		checkIntegration();
		// eslint-disable-next-line
	}, [orgId, open, selectedVisit?.id]);

	const tabItems = [
		{
			key: 'visitors',
			label: 'Visitors',
			children: <VisitorsTab redFlags={redFlags} isProfileDrawerOpen={isProfileDrawerOpen} setIsProfileDrawerOpen={setIsProfileDrawerOpen} />
		},
		{
			key: 'details',
			label: 'Details',
			children: <DetailsTab />
		},
		...(hasVisitorlogRead ? [{
			key: 'notes',
			label: 'Notes',
			children: <NotesTab isActive={activeKey === 'notes'} />,
		}] : []),
		...(hasGuestpassRead && isIntegrated && isTokenValid ? [{
			key: 'guestPass',
			label: 'Guest Pass',
			children: <GuestPassTab />
		}] : []),
		...(hasGuestpassRead && isIntegrated && isTokenValid ? [{
			key: 'guestPassLog',
			label: 'Guest Pass Log',
			children: <GuestPassLogTab />
		}] : [])
	];

	return (
		<OpDrawer
			title='Visitors'
			width={DRAWER_WIDTH}
			open={open}
			onClose={onClose}
			extra={<VisitorsDrawerHeader onClose={onClose} setLoading={setLoading} />}
			footer={<VisitorsDrawerFooter />}
		>
			{loading ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				</div>
			) : (
				<OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
			)}
		</OpDrawer >
	);
};

export default VisitorsDrawer;
