import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { DATE_TIME_AM_PM_FORMAT } from "constants/dates";
import dayjs from 'dayjs';
import { formatFullName } from "utils/utils";
import { getStatusColor, getStatusNameById } from "utils/visitorsHelper";
import { OpCard } from "components/customAntd/DLS/OpCard/OpCard";
import { useState } from "react";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { OpModal } from "components/customAntd/DLS/OpModal/OpModal";
import { OpButton } from "components/customAntd/DLS/OpButton/OpButton";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import STATUS from "constants/status";
import { useConfirmModal } from "utils/customHooks/useConfirmModal";
import { postRequest } from "api/apiClient";
import { List, notification } from "antd";
import { DenyDetails } from "types/denyTypes";

interface ProfileTabProps {
    visitorPhotoUrl: string | null;
    denyDetails?: DenyDetails;
}

const ProfileTab: React.FC<ProfileTabProps> = ({ visitorPhotoUrl, denyDetails }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisit, selectedVisitor } = useSelector((state: RootState) => state.visits);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const [isVisitorPhotoModalOpen, setIsVisitorPhotoModalVisible] = useState(false);
    const [isDeniedEntryModalOpen, setIsDeniedEntryModalOpen] = useState(false);

    const confirmModal = useConfirmModal();

    const initialValues = {
        fullName: formatFullName(selectedVisitor?.firstName!, selectedVisitor?.middleName!, selectedVisitor?.lastName!),
        status: getStatusNameById(selectedVisitor?.status!, selectedVisit?.scheduleStart!),
        email: selectedVisitor?.email,
        mobilePhone: selectedVisitor?.mobilePhone,
        signIn: selectedVisitor?.signIn ? dayjs(selectedVisitor.signIn).format(DATE_TIME_AM_PM_FORMAT) : undefined,
        signOut: selectedVisitor?.signOut ? dayjs(selectedVisitor.signOut).format(DATE_TIME_AM_PM_FORMAT) : undefined,
    };

    const resendInvitation = () => {
        confirmModal({
            title: ('Resend Invitation'),
            content: ('Resend this invitation?'),
            okText: ('Yes'),
            cancelText: ('No'),
            onOk: () => {
                try {
                    postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/invitationEmail`, {
                        visitorId: selectedVisitor?.id
                    });
                    notification.success({
                        message: 'Success',
                        description: 'The invitation link has been successfully sent to the visitor’s email.',
                        placement: 'bottomRight',
                    });
                } catch {
                    notification.error({
                        message: 'Error',
                        description: 'An error occurred while sending the invitation. Please try again.',
                        placement: 'bottomRight',
                    });
                }
            },
        });
    };

    const handleStatusClick = () => {
        if (selectedVisitor?.status === STATUS.DENIED_ENTRY.id) {
            setIsDeniedEntryModalOpen(true);
        }
    };

    return (
        <>
            <OpForm
                onSubmit={() => console.log()}
                hasError={false}
                defaultButtons={false}
                initialValues={initialValues}
                isReadOnly={true}
            >
                <OpRow gutter={24}>
                    <OpCol span={12}>
                        <OpForm.Input readOnly label="Full Name" name="fullName" />
                        <OpRow gutter={16}>
                            <OpCol span={selectedVisitor?.status === STATUS.DENIED_ENTRY.id ? 21 : 24}>
                                <OpForm.Input
                                    readOnly
                                    label="Status"
                                    name="status"
                                    style={{
                                        backgroundColor: getStatusColor(getStatusNameById(selectedVisitor?.status!, selectedVisit?.scheduleStart!)),
                                        color: 'white',
                                        WebkitTextFillColor: 'white',
                                        opacity: 1,
                                    }}
                                />
                            </OpCol>
                            {selectedVisitor?.status === STATUS.DENIED_ENTRY.id && (
                                <OpCol span={3}>
                                    <OpButton
                                        style={{ width: "30px", marginTop: '30px' }}
                                        icon={<InfoCircleOutlined />}
                                        onClick={handleStatusClick}
                                    />
                                </OpCol>
                            )}
                        </OpRow>

                        <OpForm.Input readOnly label="Mobile Phone" name="mobilePhone" />
                    </OpCol>
                    <OpCol span={12}>
                        <OpCard
                            hoverable={!!visitorPhotoUrl}
                            style={{
                                width: '100%',
                                height: '280px',
                                borderRadius: '30px',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                if (visitorPhotoUrl) {
                                    setIsVisitorPhotoModalVisible(true);
                                }
                            }}
                        >
                            {visitorPhotoUrl ? (
                                <img src={visitorPhotoUrl}
                                    alt='Visitor'
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '30px',
                                    }}
                                    onError={(e) => {
                                    }}
                                />
                            ) : (
                                <UserOutlined style={{ fontSize: '64px', color: '#ccc' }} />
                            )}
                        </OpCard>
                    </OpCol>
                </OpRow>
                <OpRow gutter={16}>
                    <OpCol span={18}>
                        <OpForm.Input readOnly label="Email" name="email" />
                    </OpCol>
                    <OpCol span={6}>
                        <OpButton
                            style={{ width: "100%", marginTop: '30px' }}
                            onClick={resendInvitation}
                            disabled={(selectedVisitor?.status !== STATUS.PENDING.id) || (!initialValues.email) || (!invitationConfig?.sendEmail) ? true : false}
                        >
                            Resend Invitation
                        </OpButton>
                    </OpCol>
                </OpRow>
                <OpRow gutter={16}>
                    <OpCol span={12}>
                        <OpForm.Input readOnly label="Sign In Time" name="signIn" />
                    </OpCol>
                    <OpCol span={12}>
                        <OpForm.Input readOnly label="Sign Out Time" name="signOut" />
                    </OpCol>
                </OpRow>
            </OpForm>

            {(isVisitorPhotoModalOpen) && (
                <OpModal
                    open={isVisitorPhotoModalOpen}
                    title="Visitor Photo"
                    centered
                    onCancel={() => setIsVisitorPhotoModalVisible(false)}  // This handles closing when "X" is clicked
                    onClose={() => setIsVisitorPhotoModalVisible(false)}   // This handles closing with custom buttons
                    footer={[
                        <OpButton key="close" type="primary" onClick={() => setIsVisitorPhotoModalVisible(false)}>
                            Close
                        </OpButton>
                    ]}
                >
                    {visitorPhotoUrl && <img src={visitorPhotoUrl} alt="Visitor" style={{ width: '100%' }} />}
                </OpModal>
            )}

            {/* Denied Entry Details Modal */}
            {isDeniedEntryModalOpen && (
                <OpModal
                    open={isDeniedEntryModalOpen}
                    title="Denied Entry Details"
                    centered
                    onCancel={() => setIsDeniedEntryModalOpen(false)}
                    footer={[
                        <OpButton key="close" type="primary" onClick={() => setIsDeniedEntryModalOpen(false)}>
                            Close
                        </OpButton>,
                    ]}
                >
                    <List
                        bordered
                        dataSource={[
                            { title: "Reason for Denial:", value: denyDetails?.reason?.name || "---" },
                            { title: "Effective Date:", value: denyDetails?.deniedAt ? dayjs(denyDetails?.deniedAt).utc(true).local().format(DATE_TIME_AM_PM_FORMAT) : "---" },
                        ]}
                        renderItem={(item) => (
                            <List.Item>
                                <strong>{item.title}</strong> <span>{item.value}</span>
                            </List.Item>
                        )}
                    />
                </OpModal>
            )}

        </>
    );
};

export default ProfileTab;
